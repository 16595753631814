import { Controller } from "@hotwired/stimulus";
import {
  cancelOpenModalEvent,
  closeModal,
  closeSidePanel,
  isInputFilled,
  isStringTrue,
  toggleClass,
} from "../../utils/misc";
import { roundToTwoDecimals } from "../../utils/number_utils";
import { flashAlert, notifyError } from "../../components/flash_alert";
import { handleHttpResponse } from "../../utils/http_utils";
import { updateSalesInvoicesTableRows } from "../../datatables/sales_invoices_datatable";

export default class extends Controller {
  static targets = [
    "percentTypeRadioButton",
    "instalmentRawValue",
    "instalmentCurrencySymbol",
    "instalmentComputedGrossAmount",
    "instalmentComputedGrossAmountText",
    "instalmentType",
    "instalmentNote",
    "quoteId",
  ];

  connect() {
    window.$(this.element).on("show.bs.modal", (event) => {
      this.quote = this._formattedQuote(event);
      this._initModal(event);
    });
  }

  _initModal(event) {
    if (isStringTrue(this.quote.hasInstalmentInvoices)) {
      notifyError("Un acompte a déjà été facturé pour ce devis.");
      cancelOpenModalEvent(event);
      return;
    }

    this._resetInputs();
    this._setQuoteId();
    this._setQuoteNotes();
    this._setCurrencySymbol();
  }

  onSubmitEnd(event) {
    const { message } = JSON.parse(event.detail[0].response);

    handleHttpResponse({
      payload: event.detail[0],
      onSuccess: (response) => {
        const salesInvoices = JSON.parse(response).sales_invoices;
        updateSalesInvoicesTableRows(salesInvoices);

        flashAlert(message);
        closeModal(this.element.id);
        closeSidePanel();
      },
      onError: () => {
        notifyError(message);
      },
    });
  }

  computeInstalmentGrossAmount() {
    this.instalmentComputedGrossAmountTarget.value = this._instalmentGrossAmount(this.quote.grossTotal);

    toggleClass(this.instalmentComputedGrossAmountTextTarget, "hidden", this._isPercentType());
  }

  _instalmentGrossAmount(quoteAmount) {
    if (!isInputFilled(this.instalmentRawValueTarget)) {
      return 0;
    }

    const instalmentRawValue = this.instalmentRawValueTarget.value;
    let instalmentComputedGrossAmount = instalmentRawValue;

    if (this._isPercentType()) {
      instalmentComputedGrossAmount = this._percentValue(quoteAmount, instalmentRawValue);
    }

    return instalmentComputedGrossAmount;
  }

  _isPercentType() {
    return this.percentTypeRadioButtonTarget.checked === true;
  }

  _percentValue(quoteAmount, inputValue) {
    return roundToTwoDecimals(quoteAmount * (parseFloat(inputValue) / 100));
  }

  _resetInputs() {
    this.percentTypeRadioButtonTarget.checked = true;

    this.instalmentComputedGrossAmountTarget.value = "0";

    [this.instalmentRawValueTarget].forEach((target) => (target.value = ""));
  }

  _setQuoteId() {
    this.quoteIdTarget.value = this.quote.id;
  }

  _setQuoteNotes() {
    this.instalmentNoteTarget.value = this.quote.notes;
  }

  _setCurrencySymbol() {
    this.instalmentCurrencySymbolTargets.forEach((target) => {
      target.innerText = this.quote.currencySymbol;
    });
  }

  _formattedQuote = (event) => {
    const { id, gross_total, currency_symbol, notes, has_instalment_invoices } = JSON.parse(
      event.relatedTarget.dataset.details
    );

    return {
      id: id,
      grossTotal: gross_total,
      currencySymbol: currency_symbol,
      notes: notes,
      hasInstalmentInvoices: has_instalment_invoices,
    };
  };
}
